<template>
  <div class="report-main">
    <lj-nav></lj-nav>
    <router-view />
    <lj-footer></lj-footer>
  </div>
</template>

<script>
import LjFooter from './../../../components/footer.vue'
import LjNav from './../../../components/nav.vue'
export default {
  data: function () {
    return {}
  },
  components: {
    LjFooter,
    LjNav
  },
  methods: {}
}
</script>

<style lang="less">
@import url('./../../../Public/resources/css/templates/home/major/std_result.min.css');
@import url('./../../../Public/resources/css/templates/home/major/std_result_index.min.css');
@import url('./../../../Public/resources/css/templates/home/major/index.min.css');
@import url('./../../../Public/resources/css/components/form.min.css');

.report-main {
  background: #cfd1cc;
  color: #000;
}

@media only screen and (max-width: 480px) {
  .main {
    padding: 1.6rem 0;
  }

  .main .main-parts-wrapper {
    padding: 1rem 0;
  }
}
</style>
